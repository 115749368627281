import { Field } from 'formik';
import { WaveFieldProps } from './wave_field_props';

export default function WaveSelectInput(
  props: WaveFieldProps & { options: string[] },
) {
  return (
    <div className="sm:col-span-4">
      {props.label && (
        <label
          htmlFor="user_facing_summary"
          className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
      )}
      <div className="mt-1 flex rounded-md shadow-sm">
        <Field
          name={props.name}
          required={props.required}
          as="select"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md border-gray-300">
          <>
            <option></option>
            {props.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </>
        </Field>
      </div>
    </div>
  );
}
