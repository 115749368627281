import { useState } from 'react';
import { Link } from 'react-router-dom';
import { InternalToolsApiEndpoints } from '../../coaching/api_endpoints';
import CoachingButton from '../../coaching/components/coaching_button';
import CoachingModal from '../../coaching/components/coaching_modal';
import {
  postResource,
  useFetchResource,
} from '../../utils/api_request_helpers';
import { Field, Formik } from 'formik';
import FormButton from '../../coaching/components/forms/form_button';

export interface ChatBot {
  id: number;
  name: string;
  description: string;
  seed_prompt: string;
  avatar_file_name?: string;
}

export default function ChatBotsPage() {
  const [loading, , chatBots, refetchChatBots] = useFetchResource<ChatBot[]>(
    InternalToolsApiEndpoints.chatBots,
  );

  const [showForm, setShowForm] = useState(false);
  const [editingBot, setEditingBot] = useState<ChatBot | undefined>(undefined);

  if (loading || !chatBots) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="mt-5 ml-10">
        <CoachingButton
          title="Create New Chat Bot"
          onClick={() => {
            setShowForm(!showForm);
          }}
        />
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg my-4 mx-10">
        <div className="px-4 py-5 sm:p-6">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  ID
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Name
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Description
                </th>
                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Seed Prompt
                </th>
                <th />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {chatBots.map((bot) => (
                <tr key={bot.id}>
                  <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm font-medium text-gray-900">
                    {bot.id}
                  </td>
                  <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm text-gray-900">
                    {bot.name}
                  </td>
                  <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm text-gray-900">
                    {bot.description}
                  </td>
                  <td className="whitespace-wrap px-10 py-4 text-sm text-gray-900">
                    {bot.seed_prompt}
                  </td>
                  <td className="whitespace-nowrap px-10 py-4 text-sm text-gray-900">
                    <div
                      className="hover:underline cursor-pointer text-blue-500"
                      onClick={() => {
                        setEditingBot(bot);
                        setShowForm(true);
                      }}>
                      Edit
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showForm && (
        <ChatBotForm
          existingBot={editingBot}
          onClose={() => {
            setShowForm(false);
          }}
          onSuccess={() => {
            refetchChatBots();
            setShowForm(false);
          }}
        />
      )}
    </>
  );
}

function ChatBotForm({
  existingBot,
  onClose,
  onSuccess,
}: {
  existingBot?: ChatBot;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const initialFormValues = {
    name: existingBot?.name || '',
    avatar_file_name: existingBot?.avatar_file_name || '',
    description: existingBot?.description || '',
    seed_prompt: existingBot?.seed_prompt || '',
  };
  const submitToServer = async (values: typeof initialFormValues) => {
    const serverUrl = existingBot
      ? InternalToolsApiEndpoints.chatBot(existingBot.id)
      : InternalToolsApiEndpoints.chatBots;

    const [status] = await postResource(serverUrl, values);

    if (status !== 201 && status !== 200) {
      alert(
        'Unable to create chat bot. Please try again or contact engineering',
      );
      return;
    }

    onSuccess();
  };

  return (
    <CoachingModal
      open
      onClose={() => {
        onClose();
      }}>
      <div>
        <Formik initialValues={initialFormValues} onSubmit={submitToServer}>
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="space-y-2">
              <div>
                <label htmlFor="name" className="block">
                  Bot User Facing Name
                </label>
                <Field name="name" className="px-3 py-2 border rounded w-3/4" />
              </div>

              <div>
                <label htmlFor="avatar_file_name" className="block">
                  Bot Avatar File Name
                </label>
                <Field
                  name="avatar_file_name"
                  className="px-3 py-2 border rounded w-3/4"
                />
              </div>

              <div>
                <label htmlFor="description" className="block">
                  Bot User Facing Description
                </label>
                <Field
                  name="description"
                  className="px-3 py-2 border rounded w-3/4"
                />
              </div>
              <div>
                <label htmlFor="seed_prompt" className="block">
                  Standard response wrapper prompt
                </label>
                <Field
                  as="textarea"
                  name="seed_prompt"
                  className="w-3/4 px-3 py-2 border rounded"
                  required
                />
              </div>
              <FormButton
                title={existingBot ? 'Save' : 'Create'}
                disabled={isSubmitting}
              />
            </form>
          )}
        </Formik>
      </div>
    </CoachingModal>
  );
}
