import React, { useEffect, useRef, useState } from 'react';
import Daily from '@daily-co/daily-js';

interface DailyVideoSessionParams {
  roomUrl: string;
  token: string;
}

export default function DailyVideoSession({
  roomUrl,
  token,
}: DailyVideoSessionParams) {
  const videoRef = useRef<HTMLIFrameElement | null>(null);
  const [leftMeeting, setLeftMeeting] = useState(false);

  useEffect(() => {
    if (videoRef.current) return;

    const dailyCall = Daily.createFrame({
      showLeaveButton: true,
      iframeStyle: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '1000',
      },
    });
    videoRef.current = dailyCall.iframe();

    dailyCall.on('left-meeting', () => {
      setLeftMeeting(true);
    });

    // Join the room
    dailyCall.join({ url: roomUrl, token: token });

    // Cleanup
    return () => {
      dailyCall.destroy();
    };
  }, [roomUrl, token]);

  return (
    <>
      {leftMeeting && (
        <div className="flex items-center justify-center mt-24">
          <div className="text-center">
            <h1>Call Completed</h1>
          </div>
        </div>
      )}
    </>
  );
}
