import { useSearchParams } from 'react-router-dom';
import DailyVideoSession from '../components/daily_video_session';

export default function CoachVideoSessionPage() {
  const [searchParams] = useSearchParams();
  const videoName = searchParams.get('daily_room_name');
  const token = searchParams.get('token');

  if (!videoName || !token) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <div className="text-center">
            <h2>Missing Necessary Attributes</h2>
            <p>
              Double check the URL & if the issue persists contact engineering
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <DailyVideoSession
        roomUrl={`https://wavelife.daily.co/${videoName}`}
        token={token}
      />
    </>
  );
}
