import { Formik } from 'formik';
import { CmsApiEndpoints } from '../../../coaching/api_endpoints';
import FormButton from '../../../coaching/components/forms/form_button';
import WaveStringField from '../../../coaching/components/forms/wave_string_field';
import {
  postResource,
  updateResource,
} from '../../../utils/api_request_helpers';
import { ByteGrouping } from '../../pages/explore_category_groupings_page';

interface NewByteGroupingFormProps {
  existingGrouping?: ByteGrouping;
  categoryId?: number | string;
  onSuccess: () => void;
}
export default function ByteGroupingForm({
  categoryId,
  onSuccess,
  existingGrouping,
}: NewByteGroupingFormProps) {
  const submitValuesToServer = async (values: typeof initialValues) => {
    let status;
    if (existingGrouping?.id) {
      [status] = await updateResource(
        CmsApiEndpoints.byteGrouping(existingGrouping.id),
        values,
      );
    } else {
      [status] = await postResource(
        CmsApiEndpoints.domainByteGroupings(categoryId!),
        values,
      );
    }

    if (status > 299) {
      alert(
        `Unable to ${
          existingGrouping?.id ? 'update' : 'create'
        } grouping, please try again or contact engineering`,
      );
      return;
    }

    onSuccess();
  };

  const initialValues = {
    title: existingGrouping?.title || '',
  };
  return (
    <Formik initialValues={initialValues} onSubmit={submitValuesToServer}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <WaveStringField name="title" label="Title" required />

          <FormButton />
        </form>
      )}
    </Formik>
  );
}
