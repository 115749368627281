import { useParams } from 'react-router-dom';
import {
  postResource,
  useFetchResource,
} from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import UserRecordSection from './user_record_section';
import { format } from 'date-fns';
import CoachingButton from '../coaching_button';
import { useState } from 'react';
import { Field, Formik } from 'formik';
import FormButton from '../forms/form_button';

interface Memo {
  id: number;
  content: string;
  created_at: string;
}

export default function Memos() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [showNewForm, setShowNewForm] = useState(false);

  const [loading, , memos, refetchMemos] = useFetchResource<Memo[]>(
    CoachingApiEndpoints.userMemos(userId),
  );

  return (
    <UserRecordSection
      title="Memos"
      loading={loading}
      actionButton={
        <CoachingButton
          title={showNewForm ? 'Cancel' : 'Add New Memo'}
          onClick={() => {
            setShowNewForm(!showNewForm);
          }}
        />
      }>
      {showNewForm && (
        <NewMemoForm
          userId={userId}
          onSuccess={() => {
            refetchMemos();
            setShowNewForm(false);
          }}
        />
      )}
      <ul className="space-y-3">
        {memos?.map((memo) => (
          <li
            key={memo.id}
            className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
            <div>
              <div className="text-gray-500 mb-2">
                {format(new Date(memo.created_at), 'MMMM do yyyy')} at{' '}
                {format(new Date(memo.created_at), 'h:mm a')}
              </div>

              <div className="my-2">{memo.content}</div>
            </div>
          </li>
        ))}
      </ul>
    </UserRecordSection>
  );
}

interface NewMemoFormProps {
  userId: number | string;
  onSuccess: () => void;
}
function NewMemoForm({ userId, onSuccess }: NewMemoFormProps) {
  const initialValues = {
    content: '',
  };

  const submitToServer = async (values: typeof initialValues) => {
    const [status] = await postResource(
      CoachingApiEndpoints.userMemos(userId),
      values,
    );

    if (status !== 201) {
      alert('Unable to save memo please try again or contact engineering');
      return;
    }

    onSuccess();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitToServer}>
      {({ handleSubmit }) => (
        <div className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4 my-4">
          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="sm:col-span-4">
              <div className="">
                <Field
                  name="content"
                  as="textarea"
                  style={{ minHeight: 150 }}
                  className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
            <FormButton />
          </form>
        </div>
      )}
    </Formik>
  );
}
