import { Field, Formik } from 'formik';
import { useState } from 'react';
import { CmsApiEndpoints } from '../../../coaching/api_endpoints';
import CoachingModal from '../../../coaching/components/coaching_modal';
import FormButton from '../../../coaching/components/forms/form_button';
import {
  deleteResource,
  postResource,
  useFetchResource,
} from '../../../utils/api_request_helpers';
import { Byte } from '../../pages/bytes_page';
import { ByteGrouping } from '../../pages/explore_category_groupings_page';
import ByteGroupingForm from './byte_grouping_form';

interface ByteGroupingEntryProps {
  grouping: ByteGrouping;
  refetchData: () => void;
}
export default function ByteGroupingEntry({
  grouping,
  refetchData,
}: ByteGroupingEntryProps) {
  const [showBytes, setShowBytes] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const deleteGrouping = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this grouping? This can't be undone.",
    );

    if (!confirm) return;

    const [status] = await deleteResource(
      CmsApiEndpoints.byteGrouping(grouping.id),
    );

    if (status !== 200) {
      alert('Unable to delete grouping. Please try again');
      return;
    }

    refetchData();
  };

  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-lg my-4">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-between">
            {' '}
            <span>{grouping.title}</span>{' '}
            <div className="flex space-x-6">
              <div
                className="text-blue-400 cursor-pointer"
                onClick={() => {
                  setShowEditForm(true);
                }}>
                Edit
              </div>
              <div
                className="text-blue-400 cursor-pointer"
                onClick={() => {
                  deleteGrouping();
                }}>
                Delete
              </div>
              <div
                className="text-blue-400 cursor-pointer"
                onClick={() => {
                  setShowBytes(!showBytes);
                }}>
                Show Bytes
              </div>
            </div>
          </div>

          {showBytes && (
            <div className="my-10">
              <BytesTable
                bytes={grouping.bytes}
                groupingId={grouping.id}
                onByteChange={refetchData}
              />
            </div>
          )}
        </div>
      </div>
      <CoachingModal open={showEditForm} onClose={() => setShowEditForm(false)}>
        <ByteGroupingForm onSuccess={refetchData} existingGrouping={grouping} />
      </CoachingModal>
    </>
  );
}

interface BytesTableProps {
  bytes: Byte[];
  groupingId: string | number;
  onByteChange: () => void;
}

function BytesTable(props: BytesTableProps) {
  const [showAddByteForm, setShowAddByteForm] = useState(false);

  const removeByte = async (byteid: number | string) => {
    const [status] = await deleteResource(
      CmsApiEndpoints.bytesInGroupings.removeByte(props.groupingId, byteid),
    );

    if (status !== 200) {
      alert('Unable to remove byte. Please try again');
      return;
    }

    props.onByteChange();
  };

  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              ID
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Type
            </th>
            <th
              scope="col"
              className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
              Title
            </th>
            <th>
              <div
                className="text-purple-500"
                onClick={() => {
                  setShowAddByteForm(!showAddByteForm);
                }}>
                Add Byte
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {props.bytes.map((byte) => (
            <tr key={byte.id}>
              <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm font-medium text-gray-900">
                {byte.id}
              </td>
              <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm text-gray-900">
                {byte.type}
              </td>
              <td className="whitespace-nowrap px-10 py-4 text-sm text-gray-900">
                {byte.title}
              </td>
              <td className="text-center">
                <div
                  className="text-blue-400 underline"
                  onClick={() => {
                    removeByte(byte.id);
                  }}>
                  Remove
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <CoachingModal
        open={showAddByteForm}
        onClose={() => {
          setShowAddByteForm(false);
        }}>
        <AddByteForm
          groupingId={props.groupingId}
          onSuccess={() => {
            setShowAddByteForm(false);
            props.onByteChange();
          }}
        />
      </CoachingModal>
    </>
  );
}

interface AddByteFormProps {
  groupingId: string | number;
  onSuccess: () => void;
}
function AddByteForm(props: AddByteFormProps) {
  const [, , bytes] = useFetchResource<Byte[]>(CmsApiEndpoints.bytes);

  const initialValues = {
    byte_id: '',
  };

  const submitToServer = async (values: typeof initialValues) => {
    const [status] = await postResource(
      CmsApiEndpoints.bytesInGroupings.addByte(props.groupingId),
      values,
    );

    if (status !== 200) {
      alert('Unable to add byte, please try again');
      return;
    }
    props.onSuccess();
  };
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={submitToServer}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="space-y-6">
            <label
              htmlFor="byte_id"
              className="block text-sm font-medium text-gray-700">
              Byte
            </label>
            <Field
              name="byte_id"
              required
              as="select"
              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300">
              <option></option>
              {bytes?.map((byte) => (
                <option key={byte.id} value={byte.id}>
                  {byte.title}
                </option>
              ))}
            </Field>

            <FormButton />
          </form>
        )}
      </Formik>
    </div>
  );
}
