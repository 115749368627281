import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CmsApiEndpoints } from '../../coaching/api_endpoints';
import CoachingButton from '../../coaching/components/coaching_button';
import CoachingModal from '../../coaching/components/coaching_modal';
import { useFetchResource } from '../../utils/api_request_helpers';
import ByteForm from '../components/byte_form';

export interface Byte {
  id: number;
  title: string;
  type: string;
}

export default function BytesPage() {
  const [loading, , bytes, refetchBytes] = useFetchResource<Byte[]>(
    CmsApiEndpoints.bytes,
  );

  const [showForm, setShowForm] = useState(false);

  if (loading || !bytes) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="mt-5 ml-10">
        <CoachingButton
          title="Create New Byte"
          onClick={() => {
            setShowForm(!showForm);
          }}
        />
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg my-4 mx-10">
        <div className="px-4 py-5 sm:p-6">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  ID
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Type
                </th>
                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Title
                </th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {bytes.map((byte) => (
                <tr key={byte.id}>
                  <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm font-medium text-gray-900">
                    {byte.id}
                  </td>
                  <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm text-gray-900">
                    {byte.type}
                  </td>
                  <td className="whitespace-nowrap px-10 py-4 text-sm text-gray-900">
                    {byte.title}
                  </td>
                  <td className="whitespace-nowrap px-10 py-4 text-sm text-gray-900">
                    {byte.type === 'SlideByte' && (
                      <a
                        href={`https://web.wavelife.io/content/bytes/${byte.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline text-blue-500">
                        View on Web
                      </a>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-10 py-4 text-sm text-gray-900">
                    <Link
                      to={`${byte.id}`}
                      className="hover:underline text-blue-500">
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <CoachingModal
        open={showForm}
        onClose={() => {
          setShowForm(false);
        }}>
        <ByteForm
          onSuccess={() => {
            refetchBytes();
            setShowForm(false);
          }}
        />
      </CoachingModal>
    </>
  );
}
