import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import HumanReadableDate from '../human_readable_date';
import UserRecordSection from './user_record_section';

interface OutreachMessage {
  id: number;
  content: string;
  created_at: string;
}

export default function Outreach() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;
  const [loading, , messages] = useFetchResource<OutreachMessage[]>(
    CoachingApiEndpoints.userOutreachMessages(userId),
  );

  return (
    <UserRecordSection title="Member Outreach Messages" loading={loading}>
      <div className="mt-6">
        <ul className="space-y-3">
          {messages?.map((message) => (
            <li
              key={message.id}
              className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
              <div className="mb-4 text-gray-500 text-sm">
                {' '}
                Sent on: <HumanReadableDate isoValue={message.created_at} />
              </div>
              <div>{message.content}</div>
            </li>
          ))}
        </ul>
      </div>
    </UserRecordSection>
  );
}
