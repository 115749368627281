import { Link } from 'react-router-dom';
import { CmsApiEndpoints } from '../../coaching/api_endpoints';
import {
  deleteResource,
  useFetchResource,
} from '../../utils/api_request_helpers';
import CoachingModal from '../../coaching/components/coaching_modal';
import CoachingButton from '../../coaching/components/coaching_button';
import { useState } from 'react';
import ExploreCategoryForm from '../components/explore_categories/explore_category_form';

export interface ContentCategory {
  id: number;
  title: string;
  rank: number;
}

export default function ExploreCategoriesPage() {
  const [loading, , categories, refetchCategories] = useFetchResource<
    ContentCategory[]
  >(CmsApiEndpoints.domains);

  const [showModal, setShowModal] = useState(false);
  const [editingCategory, setEditingCategory] = useState<ContentCategory>();

  const deleteCategory = async (category: ContentCategory) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this category?',
    );

    if (!confirmed) {
      return;
    }

    const [status] = await deleteResource(CmsApiEndpoints.domain(category.id));

    if (status !== 200) {
      alert('Unable to delete record, please try again');
      return;
    }

    await refetchCategories();
  };

  if (loading || !categories) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="mx-10">
        <div className="flex justify-between items-center mt-4">
          <h2 className="my-4 text-3xl">Explore Categories</h2>
          <div>
            <CoachingButton
              title="Create New Category"
              onClick={() => setShowModal(true)}
            />
          </div>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg my-4">
          <div className="px-4 py-5 sm:p-6">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                  <th
                    scope="col"
                    className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {categories.map((category) => (
                  <tr key={category.id}>
                    <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm font-medium text-gray-900">
                      {category.id}
                    </td>
                    <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm text-blue-400 underline">
                      <Link to={`${category.id}`}>{category.title}</Link>
                    </td>
                    <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm text-blue-400 underline">
                      <div
                        className="text-blue-400 cursor-pointer"
                        onClick={() => {
                          setEditingCategory(category);
                          setShowModal(true);
                        }}>
                        Edit
                      </div>
                    </td>
                    <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm text-blue-400 underline">
                      <div
                        className="text-blue-400 cursor-pointer"
                        onClick={() => {
                          deleteCategory(category);
                        }}>
                        Delete
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <CoachingModal
        onClose={() => {
          setShowModal(false);
        }}
        open={showModal}>
        <div>
          <ExploreCategoryForm
            existingCategory={editingCategory}
            onSuccess={() => {
              refetchCategories();
              setShowModal(false);
            }}
          />
        </div>
      </CoachingModal>
    </>
  );
}
