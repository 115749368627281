import { Outlet } from 'react-router-dom';
import CoachingNavigation from './coaching_navigation';

export default function CoachingPageContainer() {
  return (
    <div>
      <CoachingNavigation />
      <Outlet />
    </div>
  );
}
