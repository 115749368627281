interface CoachingButtonProps {
  title: string;
  onClick: () => void;
  variant?: 'primary' | 'secondary' | 'alert';
}
export default function CoachingButton(props: CoachingButtonProps) {
  const buttonColor = () => {
    if (!props.variant || props.variant === 'primary') {
      return 'bg-indigo-600 hover:bg-indigo-700';
    }

    if (props.variant === 'alert') {
      return 'bg-red-600 hover:bg-red-700';
    }
  };
  return (
    <button
      onClick={props.onClick}
      type="button"
      className={
        'inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ' +
        buttonColor()
      }>
      {props.title}
    </button>
  );
}
