import { Field, Formik } from 'formik';
import {
  postResource,
  useFetchResource,
} from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import CoachingModal from '../coaching_modal';
import FormButton from '../forms/form_button';
import {
  AssignNewContentProps,
  RecommendableByte,
} from './recommended_content';

export default function AssignNewContentForm(props: AssignNewContentProps) {
  const [loadingBytes, , assignableBytes] = useFetchResource<
    RecommendableByte[]
  >(CoachingApiEndpoints.recommendableBytes);

  const initialValues = {
    byte_id: '',
    is_homework: false,
  };

  const submitToServer = async (values: typeof initialValues) => {
    const [status] = await postResource(
      CoachingApiEndpoints.userAssignedContent(props.userId),
      values,
    );

    if (status !== 201) {
      alert(
        'Unable to complete request, if assigning homework, make sure there is a completed session note. If issue persists contact engineering',
      );
      return;
    }

    props.onSuccess();
    props.onClose();
  };

  return (
    <CoachingModal open={props.show} onClose={props.onClose}>
      <Formik initialValues={initialValues} onSubmit={submitToServer}>
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="space-y-8 divide-y divide-gray-200">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="byte_id"
                  className="block text-sm font-medium text-gray-700">
                  Byte
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <Field
                    required
                    name="byte_id"
                    as="select"
                    className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300">
                    {loadingBytes ? (
                      <option value="">loading...</option>
                    ) : (
                      <>
                        <option></option>
                        {assignableBytes?.map((byte) => (
                          <option key={byte.id} value={byte.id}>
                            {byte.title}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="is_homework"
                  className="block text-sm font-medium text-gray-700">
                  Assign as Homework
                </label>
                <div className="mt-1 flex">
                  <Field name="is_homework" type="checkbox" />
                </div>
              </div>

              <FormButton />
            </div>
          </form>
        )}
      </Formik>
    </CoachingModal>
  );
}
