import { Field, Formik } from 'formik';
import FormButton from '../../coaching/components/forms/form_button';
import { deleteResource } from '../../utils/api_request_helpers';
import { InternalToolsApiEndpoints } from '../../coaching/api_endpoints';

export default function UserDeletionPage() {
  const submitToServer = async (values: any, formik: any) => {
    const [status] = await deleteResource(
      InternalToolsApiEndpoints.user(values.userId),
    );

    if (status !== 200) {
      alert('Unable to delete user, please try again');
      return;
    }

    formik.resetForm();
    alert('User deleted');
  };
  return (
    <>
      <div className="mt-10 flex justify-between items-center px-10">
        <h1 className="text-2xl">Delete a User (Staging only)</h1>
      </div>

      <div className="bg-white overflow-hidden shadow rounded-lg my-4 p-4 mr-10">
        <Formik initialValues={{ user_id: '' }} onSubmit={submitToServer}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="space-y-6">
              <label htmlFor="userId" className="block">
                User ID
              </label>
              <Field
                name="userId"
                required
                className="block rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <FormButton title="Delete User" />
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}
