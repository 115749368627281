import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import CoachingButton from '../coaching_button';
import AssignNewContentForm from './assign_new_content_form';
import UserRecordSection from './user_record_section';

type Byte = {
  id: number;
  byte_id: number;
  byte_title: string;
};
type AssignedBytesApiResponse = {
  homework_bytes: Byte[];
  non_homework_bytes: Byte[];
};

export default function RecommendedContent() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;
  const [loading, , assignedContent, refetchAssignedContent] =
    useFetchResource<AssignedBytesApiResponse>(
      CoachingApiEndpoints.userAssignedContent(userId),
    );

  const [showModal, setShowModal] = useState(false);
  return (
    <UserRecordSection
      title="Assigned Bytes"
      loading={loading}
      actionButton={
        <CoachingButton
          title="Assign New Byte"
          onClick={() => {
            setShowModal(true);
          }}
        />
      }>
      <>
        <div>
          <h3 className="font-bold">Homework Bytes</h3>
          <ul className="space-y-3">
            {assignedContent?.homework_bytes.map((item) => (
              <li
                key={item.id}
                className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
                <div>
                  <div className="mt-2">
                    {item.byte_id} - {item.byte_title}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-6">
          <h3 className="font-bold">Non Homework Bytes</h3>
          <ul className="space-y-3">
            {assignedContent?.non_homework_bytes.map((item) => (
              <li
                key={item.id}
                className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
                <div>
                  {item.byte_id} - {item.byte_title}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <AssignNewContentForm
          show={showModal}
          userId={userId!}
          onClose={() => {
            setShowModal(false);
          }}
          onSuccess={async () => {
            setShowModal(false);
            await refetchAssignedContent();
          }}
        />
      </>
    </UserRecordSection>
  );
}

export interface AssignNewContentProps {
  userId: string | number;
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export type RecommendableByte = {
  id: number;
  title: string;
};
