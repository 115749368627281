interface FormButtonProps {
  title?: string;
  disabled?: boolean;
}
export default function FormButton({ title, disabled }: FormButtonProps) {
  return (
    <div className="sm:col-span-4">
      <button
        disabled={disabled}
        type="submit"
        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        {title || 'Submit'}
      </button>
    </div>
  );
}
