import { useState } from 'react';
import CoachingButton from '../coaching_button';
import UserRecordSection from './user_record_section';
import { useNavigate, useParams } from 'react-router-dom';
import CoachingModal from '../coaching_modal';
import { Field, Formik } from 'formik';
import FormButton from '../forms/form_button';
import { CoachingApiEndpoints } from '../../api_endpoints';
import {
  postResource,
  useFetchResource,
} from '../../../utils/api_request_helpers';
import { MessageThread } from '../../../types/coaching/message_thread';
import { standardDisplayTime } from '../../../utils/time_helpers';

export default function CoachingUserMessageThreads() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;
  const [loading, , messageThreads, refetchThreads] = useFetchResource<
    MessageThread[]
  >(CoachingApiEndpoints.userMessageThreads(userId));

  const coachingThreads = messageThreads?.filter(
    (thread) => thread.employee.recipient_type === 'Employee',
  );
  const botThreads = messageThreads?.filter(
    (thread) => thread.employee.recipient_type === 'ChatBot',
  );

  const [showNewForm, setShowNewForm] = useState(false);
  return (
    <>
      <UserRecordSection
        title="Inbox"
        loading={loading}
        actionButton={
          <CoachingButton
            title="Start New Thread"
            onClick={() => {
              setShowNewForm(!showNewForm);
            }}
          />
        }>
        <div className="px-4 py-8 sm:px-0 space-y-4">
          <div className="text-lg">Coaching Threads</div>
          {coachingThreads?.map((thread) => (
            <ThreadListItem key={thread.id} thread={thread} userId={userId} />
          ))}
        </div>
        <div className="px-4 py-8 sm:px-0 space-y-4">
          <div className="text-lg">Chat Bot Threads</div>
          {botThreads?.map((thread) => (
            <ThreadListItem key={thread.id} thread={thread} userId={userId} />
          ))}
        </div>
      </UserRecordSection>
      {showNewForm && (
        <NewThreadModal
          userId={userId}
          onClose={() => {
            setShowNewForm(false);
          }}
          onSuccess={() => {
            refetchThreads();
            setShowNewForm(false);
          }}
        />
      )}
    </>
  );
}

interface NewThreadModalProps {
  userId: string;
  onClose: () => void;
  onSuccess: () => void;
}
function NewThreadModal({ userId, onClose, onSuccess }: NewThreadModalProps) {
  const [sending, setSending] = useState(false);

  const initialValues = {
    subject: '',
  };

  const submitToServer = async (values: typeof initialValues) => {
    setSending(true);

    const [status] = await postResource(
      CoachingApiEndpoints.userMessageThreads(userId),
      values,
    );

    setSending(false);
    if (status !== 201) {
      alert('Unable to send message, please try again');
      return;
    }

    onSuccess();
  };
  return (
    <CoachingModal open={true} onClose={onClose}>
      <div className="mb-4">
        <h3>New Message Thread</h3>
      </div>
      <Formik initialValues={initialValues} onSubmit={submitToServer}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="space-y-8">
            <div>
              <div className="mt-1 flex">
                <Field
                  name="subject"
                  placeholder="Subject"
                  className="min-w-full p-3 border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-0"
                  required
                />
              </div>
            </div>
            <FormButton disabled={sending} />
          </form>
        )}
      </Formik>
    </CoachingModal>
  );
}

function ThreadListItem({
  thread,
  userId,
}: {
  thread: MessageThread;
  userId: string;
}) {
  const navigate = useNavigate();

  const displayTime = () => {
    let timeToShow = thread.messages[0]?.created_at;

    if (!timeToShow) {
      timeToShow = thread.created_at;
    }

    return standardDisplayTime(timeToShow);
  };

  return (
    <div
      className={
        'border-b px-4 py-4 flex items-center justify-between cursor-pointer ' +
        (thread.has_unread ? 'text-gray-800' : 'text-gray-500')
      }
      onClick={() => {
        navigate(`/coaching/user/${userId}/threads/${thread.id}`);
      }}>
      <div className="flex items-center space-x-2">
        <div>
          <span
            className={
              'inline-flex h-8 w-8 items-center justify-center rounded-full ' +
              (thread.messages[0]?.from_user ? 'bg-gray-500' : 'bg-orange-400')
            }>
            <span className="text-sm font-medium leading-none text-white">
              TT
            </span>
          </span>
        </div>
        <div>{thread.subject}</div>
      </div>
      <div className="flex items-center space-x-8">
        <div className="">{displayTime()}</div>
        <div className="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M17 8H19C19.5304 8 20.0391 8.21071 20.4142 8.58579C20.7893 8.96086 21 9.46957 21 10V16C21 16.5304 20.7893 17.0391 20.4142 17.4142C20.0391 17.7893 19.5304 18 19 18H17V22L13 18H9C8.73733 18.0003 8.47719 17.9486 8.23453 17.8481C7.99187 17.7475 7.77148 17.6 7.586 17.414M7.586 17.414L11 14H15C15.5304 14 16.0391 13.7893 16.4142 13.4142C16.7893 13.0391 17 12.5304 17 12V6C17 5.46957 16.7893 4.96086 16.4142 4.58579C16.0391 4.21071 15.5304 4 15 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V12C3 12.5304 3.21071 13.0391 3.58579 13.4142C3.96086 13.7893 4.46957 14 5 14H7V18L7.586 17.414Z"
              stroke="#52525B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div>{thread.messages.length}</div>
        </div>
      </div>
    </div>
  );
}
