import { Disclosure } from '@headlessui/react';
import { NavLink } from 'react-router-dom';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function CmsNavigation() {
  return (
    <Disclosure as="nav" className="bg-white border-b-1 border-black">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <div className="font-bold text-lg">CMS</div>
                </div>
                <div className="sm:ml-6 sm:flex sm:space-x-8">
                  <NavLink
                    to="bytes"
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'border-indigo-500 border-b-2' : '',
                        'text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium',
                      )
                    }>
                    Bytes
                  </NavLink>

                  <NavLink
                    to="explore-categories"
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'border-indigo-500 border-b-2' : '',
                        'text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium',
                      )
                    }>
                    Explore Categories
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
