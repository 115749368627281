import { useState } from 'react';
import { InternalToolsApiEndpoints } from '../../coaching/api_endpoints';
import CoachingButton from '../../coaching/components/coaching_button';
import {
  postResource,
  useFetchResource,
} from '../../utils/api_request_helpers';
import CoachingModal from '../../coaching/components/coaching_modal';
import { Field, Formik } from 'formik';
import FormButton from '../../coaching/components/forms/form_button';
import WaveStringField from '../../coaching/components/forms/wave_string_field';
import WaveNumberField from '../../coaching/components/forms/wave_number_field';

export default function PartnersPage() {
  const [loading, , partners, refetchPartners] = useFetchResource<any[]>(
    InternalToolsApiEndpoints.partners,
  );

  const [showForm, setShowForm] = useState(false);

  const createPartner = async (values: { name: string; slug: string }) => {
    const [status] = await postResource(
      InternalToolsApiEndpoints.partners,
      values,
    );

    if (status !== 201) {
      alert('Unable to create partner, please try again');
      return;
    }

    refetchPartners();
    setShowForm(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="mt-10 flex justify-between items-center px-10">
        <h1 className="text-2xl">Partners List</h1>
        <CoachingButton
          title="Create New Partner"
          onClick={() => {
            setShowForm(true);
          }}
        />
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg my-4">
        <div className="px-4 py-5 sm:p-6">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 px-10 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  ID
                </th>
                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Name
                </th>
                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Access Code
                </th>

                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  User Self Pay?
                </th>

                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Price Per Session
                </th>

                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Provider Referral Link
                </th>

                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  iOS App Store Link
                </th>

                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Google Play Store Link
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {partners?.map((partner) => (
                <tr key={partner.id}>
                  <td className="whitespace-wrap py-4 px-10 text-sm font-medium text-gray-900">
                    {partner.id}
                  </td>
                  <td className="whitespace-wrap py-4 px-10 text-sm">
                    {partner.name}
                  </td>
                  <td className="whitespace-wrap py-4 px-10 text-sm">
                    {partner.slug}
                  </td>

                  <td className="whitespace-wrap py-4 px-10 text-sm">
                    {partner.user_self_pay ? 'Yes' : 'No'}
                  </td>

                  <td className="whitespace-wrap py-4 px-10 text-sm">
                    {partner.session_price}
                  </td>

                  <td className="whitespace-wrap py-4 px-10 text-sm">
                    {`https://web.wavelife.io/provider-referral/${partner.slug}`}
                  </td>

                  <td className="whitespace-wrap py-4 px-10 text-sm">
                    {partner.app_store_link}
                  </td>

                  <td className="whitespace-wrap py-4 px-10 text-sm">
                    {partner.play_store_link}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <CoachingModal open={showForm} onClose={() => setShowForm(false)}>
        <div>
          <Formik
            initialValues={{ name: '', slug: '', user_self_pay: true }}
            onSubmit={createPartner}>
            {({ handleSubmit }) => (
              <form className="space-y-3" onSubmit={handleSubmit}>
                <div>
                  <WaveStringField
                    name="name"
                    label="Public Facing Name"
                    required
                  />
                </div>
                <div>
                  <WaveStringField
                    name="slug"
                    label="Unique Internal Identifier (only letters, dashes, and
                    underscores)"
                    required
                  />
                </div>
                <div>
                  <WaveNumberField
                    name="session_price"
                    label="Price Per Session"
                    min={0}
                    required
                  />
                </div>
                <div>
                  <label
                    className="inline mr-2 text-sm font-medium text-gray-700"
                    htmlFor="user_self_pay">
                    User Self Pay?
                  </label>
                  <Field type="checkbox" name="user_self_pay" />
                </div>
                <FormButton />
              </form>
            )}
          </Formik>
        </div>
      </CoachingModal>
    </>
  );
}
