import { Field, Formik } from 'formik';
import FormButton from '../../coaching/components/forms/form_button';
import { useCallback, useEffect, useState } from 'react';
import {
  fetchResource,
  postResource,
  useFetchResource,
} from '../../utils/api_request_helpers';
import { InternalToolsApiEndpoints } from '../../coaching/api_endpoints';
import LinkButton from '../../coaching/components/link_button';
import CoachingButton from '../../coaching/components/coaching_button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CoachingModal from '../../coaching/components/coaching_modal';
import { ChatBot } from './chat_bots_page';
import { MessageThread } from '../../types/coaching/message_thread';

export default function ChatBotThreadsPage() {
  const [threads, setThreads] = useState<MessageThread[]>();
  const [loading, setLoading] = useState(false);
  // const [userId, setUserId] = useState<string>();
  const [showForm, setShowForm] = useState(false);

  const [urlParams, setUrlParams] = useSearchParams();

  const userIdFromUrl = urlParams.get('user_id');

  const navigate = useNavigate();

  const fetchThreads = useCallback(async () => {
    if (!userIdFromUrl) {
      console.error('Unable to fetch threads without a user ID');
      return;
    }

    setLoading(true);
    const [status, data] = await fetchResource(
      InternalToolsApiEndpoints.botThreads(userIdFromUrl),
    );

    setLoading(false);

    if (status !== 200) {
      alert(
        'Unable to fetch threads for given user ID. Try another user ID or contact engineering',
      );
      return;
    }
    setThreads(data);
  }, [userIdFromUrl]);

  const impersonateUser = async (values: { userId: string | undefined }) => {
    if (values.userId !== undefined) {
      setUrlParams({ user_id: values.userId });
      return;
    }
    setUrlParams({});
  };

  useEffect(() => {
    if (!userIdFromUrl) {
      return;
    }
    fetchThreads();
  }, [userIdFromUrl, fetchThreads]);

  return (
    <>
      <div>
        <h2 className="text-xl font-bold">
          Experimental UI for testing AI chat bots
        </h2>

        {userIdFromUrl ? (
          <div>
            <div className="mt-4">Impersonating User ID: {userIdFromUrl}</div>
            <div>
              <LinkButton
                title="Stop Impersonating"
                onClick={() => {
                  impersonateUser({ userId: undefined });
                  setThreads(undefined);
                }}
              />
            </div>
            <div className="mt-4">
              {loading || !threads ? (
                <div>Loading...</div>
              ) : (
                <div className="px-2">
                  <div className="flex justify-between">
                    <h3>Existing Threads</h3>
                    <CoachingButton
                      title="create new thread"
                      onClick={() => {
                        setShowForm(true);
                      }}
                    />
                  </div>
                  <div className="mt-2 space-y-4">
                    {threads.map((thread) => (
                      <div
                        key={thread.id}
                        className="bg-white overflow-hidden shadow rounded-lg p-4 cursor-pointer"
                        onClick={() => {
                          navigate(`/internal/chat_bot_threads/${thread.id}`);
                        }}>
                        <div className="flex justify-between">
                          <div className="">
                            <div>
                              <div className="font-bold">
                                {thread.employee.name}
                              </div>
                            </div>
                            <div>
                              {thread.id} - {thread.subject}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <div>Select User ID to Impersonate</div>
            <Formik initialValues={{ userId: '' }} onSubmit={impersonateUser}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex align-middle justify-items-center items-center">
                    <Field
                      className="my-4 border p-2 "
                      name="userId"
                      placeholder="User ID"
                    />
                    <div className="ml-4">
                      <FormButton />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
      <CoachingModal
        open={showForm}
        onClose={() => {
          setShowForm(false);
        }}>
        <NewThreadForm
          userId={userIdFromUrl!}
          onSuccess={() => {
            fetchThreads();
            setShowForm(false);
          }}
        />
      </CoachingModal>
    </>
  );
}

function NewThreadForm({
  userId,
  onSuccess,
}: {
  userId: string;
  onSuccess: () => void;
}) {
  const [, , bots] = useFetchResource<ChatBot[]>(
    InternalToolsApiEndpoints.chatBots,
  );

  const newThreadInitialValues = {
    subject: '',
    chat_bot_id: '',
  };

  const createNewThread = async (values: typeof newThreadInitialValues) => {
    const [status] = await postResource(
      InternalToolsApiEndpoints.botThreads(userId),
      values,
    );

    if (status !== 201) {
      alert('Unable to create thread. Please try again or contact engineering');
      return;
    }
    onSuccess();
  };

  return (
    <div>
      <Formik initialValues={newThreadInitialValues} onSubmit={createNewThread}>
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="space-y-2">
            <div>
              <label htmlFor="name" className="block">
                Bot
              </label>
              <Field
                as="select"
                name="chat_bot_id"
                required
                className="px-3 py-2 border rounded w-3/4 text-black">
                <option key="default" value="" />
                {bots?.map((bot) => (
                  <option key={bot.id} value={bot.id}>
                    {bot.name}
                  </option>
                ))}
              </Field>
            </div>
            <div>
              <label htmlFor="name" className="block">
                Subject
              </label>
              <Field
                name="subject"
                className="px-3 py-2 border rounded w-3/4"
                required
              />
            </div>
            <FormButton title="Create" disabled={isSubmitting} />
          </form>
        )}
      </Formik>
    </div>
  );
}
