import { Field } from 'formik';
import { WaveFieldProps } from './wave_field_props';

export default function WaveCheckbox(props: WaveFieldProps) {
  return (
    <div className="sm:col-span-4">
      {props.label && (
        <label
          htmlFor="user_facing_summary"
          className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
      )}
      <div className="mt-1 flex">
        <Field
          aria-describedby="comments-description"
          name={props.name}
          as="input"
          type="checkbox"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
      </div>
    </div>
  );
}
