import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import UserRecordSection from './user_record_section';

interface DemographicsAssessment {
  ability_status: string;
  education_level: string;
  ethnic_ratial_identity: string;
  gender: string;
  income: string;
  indigenous_heirtage: string;
  national_origin: string;
  occupation: string;
  relationship_description: string;
  relationship_status: string;
  religion_or_spriituality: string;
  sexuality: string;
}

export default function DemographicsAssessments() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , demographicAssessment] =
    useFetchResource<DemographicsAssessment>(
      CoachingApiEndpoints.usersDemographicAssessments(userId),
    );

  return (
    <UserRecordSection title="Demographics Assessment" loading={loading}>
      <div className="px-4 py-8 sm:px-0">
        <div className="bg-white shadow overflow-hidden rounded-md">
          <div className="p-4 space-y-4">
            <p>Ability Status: {demographicAssessment?.ability_status}</p>
            <p>
              Religion or Spirituality:{' '}
              {demographicAssessment?.religion_or_spriituality}
            </p>
            <p>
              Ethic or Racial Identity:{' '}
              {demographicAssessment?.ethnic_ratial_identity}
            </p>
            <p>Education Level: {demographicAssessment?.education_level}</p>
            <p>Occupation: {demographicAssessment?.occupation}</p>
            <p>Income: {demographicAssessment?.income}</p>
            <p>Sexuality: {demographicAssessment?.sexuality}</p>
            <p>
              Indigenous heritage: {demographicAssessment?.indigenous_heirtage}
            </p>
            <p>National Origin: {demographicAssessment?.national_origin}</p>
            <p>Gender: {demographicAssessment?.gender}</p>
            <p>
              Relationship Description:{' '}
              {demographicAssessment?.relationship_description}
            </p>
            <p>
              Relationship Status: {demographicAssessment?.relationship_status}
            </p>
          </div>
        </div>
      </div>
    </UserRecordSection>
  );
}
