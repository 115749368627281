import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import HumanReadableDate from '../human_readable_date';
import UserRecordSection from './user_record_section';
import { useState } from 'react';

interface ByteActivity {
  id: number;
  type: string;
  created_at: string;
  byte: {
    id: number;
    type: string;
    title: string;
  };
}

export default function CompletedBytes() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , completedBytes] = useFetchResource<ByteActivity[]>(
    CoachingApiEndpoints.userCompletedBytes(userId),
  );

  const humanReadableEventType = (event: ByteActivity) => {
    if (event.type === 'ByteStart') {
      return 'Started Byte';
    }

    if (event.type === 'ByteCompletion') {
      const humanFriendlyByteType = event.byte.type.replace('Byte', '');
      return `Completed ${humanFriendlyByteType} Byte`;
    }
  };

  return (
    <UserRecordSection title="Previously Completed Bytes" loading={loading}>
      <ul className="space-y-3">
        {completedBytes?.map((completion) => (
          <li
            key={`${completion.type} - ${completion.id}`}
            className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
            <div>
              <div className="flex justify-between">
                <div>
                  <span className="font-normal text-sm italic">
                    {humanReadableEventType(completion)}
                  </span>
                </div>
                <div>
                  <HumanReadableDate isoValue={completion.created_at} />
                </div>
              </div>
              <div className="mt-2">
                <span className="font-semibold text-xl">
                  {completion.byte.title}
                </span>
                <span className="text-sm italic">{`(${completion.byte.id})`}</span>
              </div>

              {completion.type === 'ByteCompletion' &&
                completion.byte.type === 'QuizByte' && (
                  <QuizResult byteDetails={completion.byte} />
                )}
            </div>
          </li>
        ))}
      </ul>
    </UserRecordSection>
  );
}

function QuizResult({ byteDetails }: { byteDetails: any }) {
  const [showResult, setshowResult] = useState(false);

  const resultOptions: Record<number, any> = {};
  byteDetails.quiz_metadata.results.forEach((r: any) => {
    resultOptions[r.score] = r;
  });

  const quizAnswers = byteDetails.relevant_data[0]?.answers as
    | number[]
    | undefined;

  function getMostCommonScore(scores?: number[]): number | undefined {
    if (!scores) return undefined;

    const counts = new Map<number, number>();

    scores?.forEach((num) => {
      const count = counts.get(num) || 0;
      counts.set(num, count + 1);
    });

    let mostCommonNumber = null;
    let maxCount = 0;

    counts.forEach((count, num) => {
      if (count > maxCount) {
        maxCount = count;
        mostCommonNumber = num;
      }
    });

    return mostCommonNumber || scores?.[0] || 0;
  }

  function computeQuizResult() {
    const mostCommonScore = getMostCommonScore(quizAnswers);
    if (!mostCommonScore) {
      return null;
    }
    return resultOptions[mostCommonScore];
  }

  const quizResult = computeQuizResult();

  return (
    <div>
      {quizResult && (
        <div
          className="text-blue-400 underline cursor-pointer text-sm"
          onClick={() => {
            setshowResult(!showResult);
          }}>
          {showResult ? 'Hide' : 'Show'} Result
        </div>
      )}

      {showResult && quizResult && (
        <div className="mt-4 border-t border-gray-400 py-2">
          <div className="font-semibold text-lg">{quizResult.title}</div>
          <div>{quizResult.text}</div>

          <div className="mt-4 p-2 text-sm">
            <div>Raw Scores:</div>
            <div>
              <pre>{JSON.stringify(quizAnswers, null, 2)}</pre>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
