import {
  postResource,
  useFetchResource,
} from '../../utils/api_request_helpers';
import { InternalToolsApiEndpoints } from '../../coaching/api_endpoints';
import { useParams } from 'react-router-dom';
import { MessageThread } from '../../types/coaching/message_thread';
import { Field, Formik, FormikHelpers } from 'formik';
import FormButton from '../../coaching/components/forms/form_button';
import { useState } from 'react';

export default function ChatBotThreadPage() {
  const params = useParams<{ threadId: string }>();
  const threadId = params.threadId!;

  const [sending, setSending] = useState(false);

  const [loading, , thread, refetchThread] = useFetchResource<MessageThread>(
    InternalToolsApiEndpoints.botThread(threadId),
  );

  const formInitialValues = {
    body: '',
  };

  const sendNewMessage = async (
    values: typeof formInitialValues,
    formik: FormikHelpers<typeof formInitialValues>,
  ) => {
    setSending(true);
    const [status] = await postResource(
      InternalToolsApiEndpoints.botThreadMessages(threadId),
      values,
    );

    setSending(false);
    if (status !== 201) {
      alert('Unable to send message. Please try again or contact engineering');
      return;
    }

    refetchThread();
    formik.resetForm();
  };

  if (loading || !thread) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <h2 className="text-xl font-bold">{thread.subject}</h2>
      <div className="mt-4 bg-white overflow-hidden shadow rounded-lg p-4 space-y-4">
        {thread.messages
          .slice()
          .reverse()
          .map((message) => (
            <div key={message.id}>
              {message.from_user ? 'User: ' : 'Bot: '}: {message.body}
            </div>
          ))}
        <div>
          <Formik initialValues={formInitialValues} onSubmit={sendNewMessage}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field as="textarea" name="body" className="w-10/12 rounded" />
                <FormButton
                  title={sending ? 'Sending...' : 'Send'}
                  disabled={sending}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
