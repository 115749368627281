import { useState } from 'react';
import CoachingButton from '../coaching_button';
import UserRecordSection from './user_record_section';
import { useParams } from 'react-router-dom';
import CoachAssignmentModal from '../coach_assignment_modal';
import {
  postResource,
  useFetchResource,
} from '../../../utils/api_request_helpers';
import { CoachingOpsApiEndpoints } from '../../api_endpoints';

export default function CareTeam() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [showModal, setShowModal] = useState(false);

  const [loading, , careTeamMembers, refetchCareTeamMembers] = useFetchResource<
    { employee_id: string; employee_name: string }[]
  >(CoachingOpsApiEndpoints.usersCoaches(userId));

  const updateCoachAssignment = async (values: {
    employee_id: string;
    user_id: string | number;
  }) => {
    const [status] = await postResource(
      CoachingOpsApiEndpoints.usersCoaches(),
      values,
    );

    if (status !== 201) {
      alert('Unable to assign user. Please try again or contact engineering');
      return;
    }

    await refetchCareTeamMembers();
  };

  return (
    <>
      <UserRecordSection title="Care Team" loading={loading}>
        <div className="px-4 py-8 sm:px-0">
          <div className="bg-white shadow overflow-hidden rounded-md">
            <ul className="divide-y divide-gray-200">
              {careTeamMembers?.map((record) => (
                <li key={record.employee_id} className="px-6 py-4">
                  <div className="flex flex-row justify-between align-middle">
                    <div>
                      <div>
                        Coach:{' '}
                        <span className="font-bold">
                          {record.employee_name}
                        </span>{' '}
                        ({record.employee_id})
                      </div>
                    </div>
                    <CoachingButton
                      title="Reassign"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </UserRecordSection>

      <CoachAssignmentModal
        user={showModal ? { id: parseInt(userId) } : undefined}
        onClose={() => {
          refetchCareTeamMembers();
          setShowModal(false);
        }}
        onSubmit={updateCoachAssignment}
      />
    </>
  );
}
