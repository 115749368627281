import { Formik } from 'formik';
import { CmsApiEndpoints } from '../../../coaching/api_endpoints';
import FormButton from '../../../coaching/components/forms/form_button';
import WaveStringField from '../../../coaching/components/forms/wave_string_field';
import {
  postResource,
  updateResource,
} from '../../../utils/api_request_helpers';
import WaveNumberField from '../../../coaching/components/forms/wave_number_field';
import { ContentCategory } from '../../pages/explore_categories_page';

interface ExploreCategoryFormProps {
  existingCategory?: ContentCategory;
  onSuccess: () => void;
}
export default function ExploreCategoryForm(props: ExploreCategoryFormProps) {
  const initialValues = {
    title: props.existingCategory?.title || '',
    rank: props.existingCategory?.rank || 0,
  };

  const submitToServer = async (values: typeof initialValues) => {
    let status;
    if (props.existingCategory?.id) {
      [status] = await updateResource(
        CmsApiEndpoints.domain(props.existingCategory.id),
        values,
      );
    } else {
      [status] = await postResource(CmsApiEndpoints.domains, values);
    }

    if (status > 299) {
      alert(
        `Unable to ${
          props.existingCategory?.id ? 'update' : 'create'
        } grouping, please try again or contact engineering`,
      );
      return;
    }

    props.onSuccess();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitToServer}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <WaveStringField name="title" label="Title" required />
          <WaveNumberField name="rank" label="Rank" required />
          <div className="mt-4">
            <FormButton title="Save Category" />
          </div>
        </form>
      )}
    </Formik>
  );
}
