import { useParams } from 'react-router-dom';
import {
  postResource,
  useFetchResource,
} from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';

import taskIcon from '../../../images/task-icon.png';
import downArrowIcon from '../../../images/arrow-down.png';
import doneButtonIcon from '../../../images/action-done-icon.png';
import { useState } from 'react';
import { Field, Formik } from 'formik';
import WaveStringField from '../forms/wave_string_field';
import FormButton from '../forms/form_button';

interface ProviderActionItem {
  id: number;
  category: string;
  description: string;
  due_on: string;
}

export default function ProviderActionItems() {
  const [collapsed, setCollapsed] = useState(false);
  const [showFutureActions, setShowFutureActions] = useState(false);

  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , actionItems, refetchActionItems] = useFetchResource<
    ProviderActionItem[]
  >(CoachingApiEndpoints.userProviderActionItems(userId));

  const futureActionItems = actionItems?.filter((actionItem) => {
    return actionItem.due_on > new Date().toISOString();
  });

  const dueActionItems = actionItems?.filter(
    (actionItem) => futureActionItems?.indexOf(actionItem) === -1,
  );

  const markItemCompleted = async (id: number) => {
    const [status] = await postResource(
      CoachingApiEndpoints.markProviderActionCompleted(id),
      {},
    );

    if (status !== 200) {
      alert('Unable to mark item as completed, please try again');
      return;
    }

    refetchActionItems();
  };

  if (collapsed) {
    return (
      <div className="fixed bottom-2 right-2 p-4 rounded-lg w-[400px] bg-indigo-50 border-gray-300">
        <div className="flex justify-between">
          <div className="flex">
            <div className="mr-2">
              <img
                className="h-[20px] w-[20px] my-1"
                src={taskIcon}
                alt="lightbulb task icon"
              />
            </div>
            <div className="text-lg font-bold">
              Tasks{' '}
              <span className="font-normal">{`(${dueActionItems?.length})`}</span>
            </div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setCollapsed(false);
            }}>
            <img
              className="h-[24px] w-[24px] transform rotate-180"
              src={downArrowIcon}
              alt="down arrow icon"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed bottom-2 right-2 p-4 rounded-lg w-[400px] h-[500px] overflow-auto bg-indigo-50 border-gray-300">
      <div className="flex justify-between mb-4">
        <div className="flex">
          <div className="mr-2">
            <img
              className="h-[20px] w-[20px] my-1"
              src={taskIcon}
              alt="lightbulb task icon"
            />
          </div>
          <div className="text-lg font-bold">Tasks</div>
        </div>

        <div
          className="cursor-pointer"
          onClick={() => {
            setCollapsed(true);
          }}>
          <img
            className="h-[24px] w-[24px]"
            src={downArrowIcon}
            alt="down arrow icon"
          />
        </div>
      </div>

      <NewTaskForm onCreated={refetchActionItems} userId={userId} />

      <div className="space-y-4">
        {dueActionItems?.map((actionItem) => (
          <ActionItemRecord
            key={actionItem.id}
            actionItem={actionItem}
            markItemCompleted={markItemCompleted}
          />
        ))}
      </div>

      <div className="mt-4">
        <div
          className="font-semibold text-gray-600 text-md cursor-pointer"
          onClick={() => {
            setShowFutureActions(!showFutureActions);
          }}>
          Future Actions {`(${futureActionItems?.length})`}
        </div>

        {showFutureActions &&
          futureActionItems?.map((actionItem) => (
            <ActionItemRecord
              key={actionItem.id}
              actionItem={actionItem}
              markItemCompleted={markItemCompleted}
            />
          ))}
      </div>
    </div>
  );
}

function ActionItemRecord({
  actionItem,
  markItemCompleted,
}: {
  actionItem: ProviderActionItem;
  markItemCompleted: (id: number) => void;
}) {
  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <div className="text-xl font-bold">{actionItem.category}</div>
          <div>{actionItem.description}</div>
          <div>{actionItem.due_on.toString()}</div>
        </div>

        <img
          className="h-[24px] w-[28px] cursor-pointer"
          src={doneButtonIcon}
          alt="down arrow icon"
          onClick={() => {
            markItemCompleted(actionItem.id);
          }}
        />
      </div>
    </div>
  );
}

function NewTaskForm({
  onCreated,
  userId,
}: {
  onCreated: () => void;
  userId: string;
}) {
  const [showForm, setShowForm] = useState(false);

  const createTask = async (values: {
    description: string;
    due_on: string;
  }) => {
    const [status] = await postResource(
      CoachingApiEndpoints.userProviderActionItems(userId),
      values,
    );

    if (status !== 201) {
      alert('Unable to create task, please try again');
      return;
    }

    onCreated();
    setShowForm(false);
  };
  return (
    <div>
      <div
        className="my-2 text-blue-400 cursor-pointer"
        onClick={() => {
          setShowForm(!showForm);
        }}>
        Add Task
      </div>

      {showForm && (
        <div className="my-4 border-b border-gray-600 py-2">
          <Formik
            initialValues={{ description: '', due_on: '' }}
            onSubmit={createTask}>
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className="space-y-2">
                <WaveStringField label="Description" name="description" />
                <div>
                  <label htmlFor="due_on" className="block">
                    Due Date
                  </label>
                  <Field type="date" name="due_on" />
                </div>
                <FormButton title="Create Task" disabled={isSubmitting} />
              </form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}
