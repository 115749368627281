import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { User } from '../../types/coaching/user';
import { useFetchResource } from '../../utils/api_request_helpers';
import UserRecordNavigationBar from '../components/user_record_navigation_bar';
import ProviderActionItems from '../components/user_record/provider_action_items';

export default function UserRecordPage() {
  const urlParams = useParams<{ userId: string }>();
  const navigate = useNavigate();

  if (!urlParams.userId) {
    navigate('/coaching', { replace: true });
  }

  const [loading, , user] = useFetchResource<User>(
    `/coaching/v1/users/${urlParams.userId}`,
  );

  if (loading || !user) {
    return null;
  }

  return (
    <div className="">
      <main className="flex">
        <div className="w-1/5">
          <UserRecordNavigationBar />
        </div>

        <div className="w-4/5 pt-10 mx-auto sm:px-6 lg:px-8 bg-white shadow-lg">
          <div className="px-4 sm:px-0">
            <Outlet />
          </div>
          <ProviderActionItems />
        </div>
      </main>
    </div>
  );
}
