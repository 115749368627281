import { format } from 'date-fns';

export default function HumanReadableDate({ isoValue }: { isoValue: string }) {
  return (
    <span>
      {format(new Date(isoValue), 'MMMM do yyyy')} at{' '}
      {format(new Date(isoValue), 'h:mm a')}
    </span>
  );
}

export function humanReadableDateString(isoValue: string) {
  const date = new Date(isoValue);
  return `${format(date, 'MMMM do yyyy')} at ${format(date, 'h:mm a')}`;
}
