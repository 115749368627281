interface ListCardProps {
  children: React.ReactNode;
}

export default function ListCard(props: ListCardProps) {
  return (
    <li className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
      {props.children}
    </li>
  );
}
