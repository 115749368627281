import { useParams } from 'react-router-dom';
import ListCard from '../../../shared/components/list_card';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import HumanReadableDate from '../human_readable_date';
import UserRecordSection from './user_record_section';

type Intention = {
  id: number;
  created_at: string;
  values: string[];
};

export default function Intentions() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;
  const [loading, , intentions] = useFetchResource<Intention[]>(
    CoachingApiEndpoints.userIntentions(userId),
  );

  return (
    <UserRecordSection title="Intentions" loading={loading}>
      <ul className="space-y-3">
        {intentions?.map((intention) => (
          <ListCard key={intention.id}>
            <div>
              <div className="text-gray-400 mb-3">
                <HumanReadableDate isoValue={intention.created_at} />
              </div>
              <div>{intention.values}</div>
            </div>
          </ListCard>
        ))}
      </ul>
    </UserRecordSection>
  );
}
