import { useParams } from 'react-router-dom';
import { CmsApiEndpoints } from '../../coaching/api_endpoints';
import { ExistingByte } from '../../types/cms/byte';
import { useFetchResource } from '../../utils/api_request_helpers';
import ByteForm from '../components/byte_form';

export default function ByteEditPage() {
  const params = useParams<{ byteId: string }>();
  const byteId = params.byteId!;

  const [loading, , byte, refetchByte] = useFetchResource<ExistingByte>(
    CmsApiEndpoints.byte(byteId),
  );

  if (loading || !byte) {
    return <div className="mx-10 my-10">Loading...</div>;
  }

  console.log('byte quiz data = ', byte.quiz_metadata?.toString());

  return (
    <>
      <div className="mx-10 my-10">
        <h2 className="text-3xl">Edit Byte {byteId}</h2>
        <div className="bg-white overflow-hidden shadow rounded-lg my-4 p-6">
          <ByteForm
            onSuccess={() => {
              alert('Byte Updated');
              refetchByte();
            }}
            byte={byte}
          />
        </div>
      </div>
    </>
  );
}
