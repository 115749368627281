import { Field } from 'formik';
import { WaveFieldProps } from './wave_field_props';

export default function WaveTextField(props: WaveFieldProps) {
  return (
    <div className="sm:col-span-4">
      {props.label && (
        <label
          htmlFor="user_facing_summary"
          className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
      )}
      <div className="mt-1 rounded-md">
        <Field
          name={props.name}
          as="textarea"
          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
        />
      </div>
    </div>
  );
}
