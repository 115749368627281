interface UserRecordSectionProps {
  title: string;
  loading: boolean;
  actionButton?: React.ReactNode;
  children: React.ReactNode;
}

export default function UserRecordSection({
  title,
  loading,
  actionButton,
  children,
}: UserRecordSectionProps) {
  return (
    <>
      <div className="flex justify-between align-middle items-center mb-5">
        <div className="text-xl font-semibold">{title}</div>
        {actionButton && <div className="">{actionButton}</div>}
      </div>
      {loading ? <div className="mt-6">Loading...</div> : children}
    </>
  );
}
