import { Outlet } from 'react-router-dom';
import CmsNavigation from './cms_navigation';

export default function CmsPageContainer() {
  return (
    <div>
      <CmsNavigation />
      <Outlet />
    </div>
  );
}
