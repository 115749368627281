import { NavLink, useParams } from 'react-router-dom';
import { User } from '../../types/coaching/user';
import { useFetchResource } from '../../utils/api_request_helpers';
import { classNames } from './coaching_navigation';

const DetailPages = [
  { name: 'Messages', href: 'messages', current: false },
  { name: 'Appointments', href: 'appointments', current: false },
  { name: 'Check Ins', href: 'checkins', current: false },
  { name: 'Byte Activity', href: 'completed_bytes', current: false },
  { name: 'Users Program', href: 'users_program_bytes', current: false },
  { name: 'Byte Recommendations', href: 'recommended_content', current: false },
  { name: 'Session Notes', href: 'session_notes', current: false },
  { name: 'Memos', href: 'memos', current: false },
  { name: 'Reflections', href: 'reflections', current: false },
  { name: 'Ewb Quiz Results', href: 'ewb_quizzes', current: false },
  { name: 'DASS Assessment', href: 'dass_assessments', current: false },
  { name: 'Demographics', href: 'demographics', current: false },
  { name: 'Intentions', href: 'intentions', current: false },
  { name: 'Care Team', href: 'care_team', current: false },
  { name: 'Pricing & Payment', href: 'payments', current: false },
  { name: 'Outreach (deprecated)', href: 'outreach', current: false },
];

export default function UserRecordNavigationBar() {
  const urlParams = useParams<{ userId: string }>();
  const [, , user] = useFetchResource<User>(
    `/coaching/v1/users/${urlParams.userId}`,
  );

  function calculateAge(birthdate: string): number {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col flex-grow pt-5 overflow-y-auto h-screen bg-gray-50">
      <div className="flex-1 flex flex-col">
        <nav className="flex-1 px-2 pb-4">
          <div className="px-2 mb-1 pb-1 border-b font-bold text-lg">
            <div>
              {user.id} - {user.name} ({user.pronouns})
            </div>
            <div className="mt-2 text-sm font-normal">
              <div className="mb-1">
                <span className="font-semibold">Age:</span>{' '}
                {calculateAge(user.birthdate)}
              </div>
              <div className="mb-1">
                <span className="font-semibold">Email:</span> {user?.email}
              </div>
              <div className="mb-1">
                <span className="font-semibold">Phone number:</span>{' '}
                {user.phone_number}
              </div>
              <div className="mb-1">
                <span className="font-semibold">Partner:</span>{' '}
                {user.partner?.name || 'N/A'}
              </div>
            </div>

            <div className="mt-2 text-sm font-normal"></div>
          </div>
          <div className="mt-5">
            {DetailPages.map((item) => (
              <NavLink
                to={item.href}
                key={item.name}
                className={({ isActive }) =>
                  classNames(
                    isActive ? 'border border-solid border-indigo-200' : '',
                    'text-indigo-600 group flex items-center px-2 py-2 text-md font-medium rounded-md',
                  )
                }>
                {item.name}
              </NavLink>
            ))}
          </div>
        </nav>
      </div>
    </div>
  );
}
