interface LinkButtonProps {
  onClick: () => void;
  title: string;
}

export default function LinkButton(props: LinkButtonProps) {
  return (
    <button
      className="text-blue-400 background-transparent py-1 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
      onClick={props.onClick}>
      {props.title}
    </button>
  );
}
