import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import UserRecordSection from './user_record_section';
import HumanReadableDate from '../human_readable_date';

interface CheckinApiResponse {
  questions: string[];
  checkins: Checkin[];
}

interface Checkin {
  id: number;
  created_at: string;
  answers: string[];
}

export default function Checkins() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , apiResponse] = useFetchResource<CheckinApiResponse>(
    CoachingApiEndpoints.userCheckins(userId),
  );

  return (
    <UserRecordSection title="In App Check Ins" loading={loading}>
      <ul className="space-y-3">
        {apiResponse?.checkins.map((checkin) => (
          <li
            key={checkin.id}
            className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
            <div>
              <div className="mb-2">
                Completed <HumanReadableDate isoValue={checkin.created_at} />
              </div>

              <div>
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Question
                      </th>
                      <th
                        scope="col"
                        className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Answer
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {apiResponse?.questions.map((question, index) => (
                      <tr key={index}>
                        <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm font-medium text-gray-900">
                          {question}
                        </td>
                        <td className="px-10 py-4 text-sm text-gray-500">
                          {checkin.answers[index]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </UserRecordSection>
  );
}
