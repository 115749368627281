import { Appointment } from '../../../../types/coaching/appointment';
import { updateResource } from '../../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../../api_endpoints';
import CoachingButton from '../../coaching_button';
import HumanReadableDate from '../../human_readable_date';

export default function PastAppointmentRecord({
  appointment,
  onAppointmentUpdated,
}: {
  appointment: Appointment;
  onAppointmentUpdated: () => Promise<void>;
}) {
  const updateAttendance = async (attended: boolean) => {
    await updateResource(CoachingApiEndpoints.userAppointment(appointment.id), {
      user_attended: attended.toString(),
    });

    onAppointmentUpdated();
  };

  return (
    <div>
      <div
        className={
          'text-lg' +
          (appointment.user_attended !== false ? '' : ' text-gray-400')
        }>
        {appointment.user_attended !== null && !appointment.user_attended && (
          <span className="mr-2">No Show - </span>
        )}
        <HumanReadableDate isoValue={appointment.scheduled_for} />
      </div>

      {appointment.session_note && (
        <div>
          {!appointment.session_note.published_on && (
            <span className="font-bold text-red-600">
              Unpublished Session Note
            </span>
          )}
        </div>
      )}

      {!appointment.session_note && appointment.user_attended === null && (
        <div>
          <div className="mb-4 pb-2 border-b border-gray-400">
            {appointment.daily_room_name && (
              <div className="mt-2 flex space-x-8">
                <div className="underline text-blue-600">
                  <a
                    href={`/coaching/video-session?daily_room_name=${appointment.daily_room_name}&token=${appointment.daily_room_token}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Join Session
                  </a>
                </div>
                <div
                  className="cursor-pointer underline"
                  onClick={() => {
                    navigator.clipboard.writeText(appointment.user_join_link);
                  }}>
                  Copy User Session Join Link
                </div>
              </div>
            )}
          </div>

          <div className="flex space-x-4 mt-2">
            <CoachingButton
              title="User Attended"
              onClick={() => {
                updateAttendance(true);
              }}
            />
            <CoachingButton
              title="User No Showed"
              onClick={() => {
                updateAttendance(false);
              }}
              variant="primary"
            />
          </div>
        </div>
      )}
    </div>
  );
}
