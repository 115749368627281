import { WaveFieldProps } from './wave_field_props';
import WaveSelectInput from './wave_select_input';

export default function WaveDomainSelectInput(props: WaveFieldProps) {
  const domains = [
    'Resilience',
    'Balance',
    'Flexibility',
    'Clarity',
    'Awareness',
    'Connection',
  ];

  return (
    <WaveSelectInput name={props.name} label={props.label} options={domains} />
  );
}
