import { Field } from 'formik';
import { WaveFieldProps } from './wave_field_props';

interface WaveNumberFieldProps extends WaveFieldProps {
  min?: number;
  max?: number;
}

export default function WaveNumberField(props: WaveNumberFieldProps) {
  return (
    <div className="">
      {props.label && (
        <label
          htmlFor="user_facing_summary"
          className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
      )}
      <div className="mt-1 border rounded-md">
        <Field
          name={props.name}
          as="input"
          required={props.required}
          type="number"
          min={props.min === undefined ? 1 : props.min}
          max={props.max || Number.MAX_SAFE_INTEGER}
          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-400 p-3"
        />
      </div>
    </div>
  );
}
