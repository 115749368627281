import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import HumanReadableDate from '../human_readable_date';
import UserRecordSection from './user_record_section';
import { useState } from 'react';

interface Reflection {
  id: number;
  content: string;
  ai_summary: string;
  created_at: string;
  reflection_prompt?: any;
}

export default function Reflections() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , reflections] = useFetchResource<Reflection[]>(
    CoachingApiEndpoints.userReflections(userId),
  );

  return (
    <UserRecordSection title="User Reflections" loading={loading}>
      <ul className="space-y-3">
        {reflections?.map((reflection) => (
          <li
            key={reflection.id}
            className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
            <ReflectionRecord reflection={reflection} />
          </li>
        ))}
      </ul>
    </UserRecordSection>
  );
}

function ReflectionRecord({ reflection }: { reflection: Reflection }) {
  const [showPrompt, setshowPrompt] = useState(false);

  return (
    <div>
      <div className="flex justify-between">
        <div>
          {reflection.reflection_prompt ? (
            <span
              className="text-blue-400 underline text-sm cursor-pointer"
              onClick={() => {
                setshowPrompt(!showPrompt);
              }}>
              {`Prompt ${reflection.reflection_prompt.id}`}
            </span>
          ) : (
            <span className="font-normal text-sm italic"> No Prompt </span>
          )}
        </div>
        <div>
          <HumanReadableDate isoValue={reflection.created_at} />
        </div>
      </div>

      {showPrompt && (
        <div className="my-3">
          <div>{reflection.reflection_prompt.title}</div>
          <div>{reflection.reflection_prompt.subtitle}</div>
        </div>
      )}

      <div className="mt-2 space-y-3">
        <div className="text-md">{reflection.content}</div>
        <div className="text-sm italic">{reflection.ai_summary}</div>
      </div>
    </div>
  );
}
