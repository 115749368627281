import { Formik, Field } from 'formik';
import { useFetchResource } from '../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../api_endpoints';
import CoachingModal from './coaching_modal';
import FormButton from './forms/form_button';
import { Coach } from '../../types/coaching/coach';

export default function CoachAssignmentModal(props: {
  user?: { id: number | string };
  onSubmit: ({
    employee_id,
    user_id,
  }: {
    employee_id: string;
    user_id: string | number;
  }) => Promise<void>;
  onClose: () => void;
}) {
  const initialValues = {
    employee_id: '',
    user_id: props.user?.id || '',
  };

  const [loading, , coaches] = useFetchResource<Coach[]>(
    CoachingApiEndpoints.coaches,
  );

  const submitToServer = async (values: typeof initialValues) => {
    await props.onSubmit(values);
    props.onClose();
  };

  return (
    <CoachingModal open={!!props.user} onClose={props.onClose}>
      <div className="w-full">
        <Formik initialValues={initialValues} onSubmit={submitToServer}>
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="space-y-8 divide-y divide-gray-200">
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="employee_id"
                    className="block text-sm font-medium text-gray-700">
                    Coach
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      name="employee_id"
                      required
                      as="select"
                      className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300">
                      {loading ? (
                        <option value="">Loading...</option>
                      ) : (
                        <>
                          <option value=""></option>
                          {coaches?.map((c) => (
                            <option key={c.id} value={c.id}>
                              {c.name}
                            </option>
                          ))}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <FormButton />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </CoachingModal>
  );
}
