import axios from 'axios';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { postResource } from '../utils/api_request_helpers';

export const AUTH_TOKEN_STORAGE_KEY = 'employee_auth_token';

export function setAuthTokenForAllRequests(token: string) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function logOutEmployee() {
  axios.defaults.headers.common['Authorization'] = '';
  localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  window.location.reload();
}

const LoginPage = () => {
  const [loginError, setLoginError] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const attemptLogin = async (formValues: any) => {
    setLoginError(false);
    setRequestInProgress(true);

    const [status, apiResponse] = await postResource(
      '/employee_login_attempts',
      formValues,
    );

    setRequestInProgress(false);

    if (status !== 200) {
      setLoginError(true);
      return;
    }

    setLoggedInEmployee(apiResponse.token);
    window.location.reload();
  };

  function setLoggedInEmployee(token: string) {
    localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
    setAuthTokenForAllRequests(token);
  }

  const initialFormValues = {
    password: '',
    email: '',
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik initialValues={initialFormValues} onSubmit={attemptLogin}>
              {({ handleSubmit }) => (
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <Field
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <Field
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  {loginError && (
                    <div className="text-red-600">
                      Unable to login, please ensure your email and password
                      match
                    </div>
                  )}

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-300"
                      disabled={requestInProgress}>
                      Sign in
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
