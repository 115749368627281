import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import UserRecordSection from './user_record_section';
import InsuranceCards from './payments/insurance_cards';

interface PaymentDetails {
  session_price: number;
  free_first_session: boolean;
  using_insurance: boolean;
}

export default function Payments() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;
  const [loading, , paymentDetails] = useFetchResource<PaymentDetails>(
    CoachingApiEndpoints.userSessionPricing(userId),
  );

  return (
    <UserRecordSection title="Session Pricing & Payments" loading={loading}>
      {paymentDetails?.using_insurance ? (
        <div>
          <div className="text-xl font-bold border-t-2 pt-4">
            Using Insurance
          </div>

          <InsuranceCards userId={userId} />
        </div>
      ) : (
        <div>
          <div className="text-xl font-bold border-t-2 pt-4">
            Session Price: ${paymentDetails?.session_price}
          </div>
          {paymentDetails?.free_first_session && (
            <div className="italic">*Includes Free First Session</div>
          )}
        </div>
      )}
    </UserRecordSection>
  );
}
