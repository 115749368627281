import { useState } from 'react';
import { User } from '../../types/coaching/user';
import {
  postResource,
  useFetchResource,
} from '../../utils/api_request_helpers';
import {
  CoachingApiEndpoints,
  CoachingOpsApiEndpoints,
} from '../api_endpoints';
import CoachingButton from '../components/coaching_button';
import CoachAssignmentModal from '../components/coach_assignment_modal';

export default function CoachingAssignmentPage() {
  const [loading, , users, refetchUsers] = useFetchResource<User[]>(
    CoachingApiEndpoints.unassignedUsers,
  );

  const [userToAssign, setUserToAssign] = useState<User>();

  if (loading || !users) {
    return null;
  }

  const assignCoach = async (values: {
    user_id: string | number;
    employee_id: string;
  }) => {
    const [status] = await postResource(
      CoachingOpsApiEndpoints.usersCoaches(),
      values,
    );

    if (status !== 201) {
      alert('Unable to assign user. Please try again or contact engineering');
      return;
    }

    await refetchUsers();
  };

  return (
    <>
      <div className="py-4">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Users Needing Coach Assignment
            </h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 py-8 sm:px-0">
              <div className="bg-white shadow overflow-hidden rounded-md">
                <ul className="divide-y divide-gray-200">
                  {users.map((record) => (
                    <li key={record.id} className="px-6 py-4">
                      <div className="flex flex-row justify-between align-middle">
                        <div>
                          <div>
                            {record.id} - {record.name}
                          </div>
                          <div className="text-sm italic">
                            {record.email} &#x2022; {record.phone_number}
                          </div>
                        </div>
                        <CoachingButton
                          title="Assign to Coach"
                          onClick={() => {
                            setUserToAssign(record);
                          }}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
      <CoachAssignmentModal
        user={userToAssign}
        onSubmit={assignCoach}
        onClose={() => {
          setUserToAssign(undefined);
        }}
      />
    </>
  );
}
