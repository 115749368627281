import { Field } from 'formik';
import { WaveFieldProps } from './wave_field_props';

export default function WaveMultiSelect(
  props: WaveFieldProps & { options: string[] },
) {
  return (
    <div className="sm:col-span-4">
      {props.label && (
        <label
          htmlFor="user_facing_summary"
          className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
      )}
      <div className="mt-1 rounded-md">
        {props.options.map((option) => (
          <div className="display-block" key={option}>
            <Field
              name={props.name}
              as="input"
              type="checkbox"
              value={option}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mr-2"
            />
            <span className="text-gray-700 text-sm">{option}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
