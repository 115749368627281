import { useFetchResource } from '../../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../../api_endpoints';

export default function InsuranceCards({ userId }: { userId: string }) {
  const [loading, , insuranceCards] = useFetchResource<any>(
    CoachingApiEndpoints.userInsuranceCards(userId),
  );

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      {insuranceCards ? (
        <div className="mt-4 space-y-4">
          <div>Uploaded At {insuranceCards?.created_at}</div>
          <div className="flex space-x-8">
            <div>
              <div className="mb-2">Front of card</div>
              <img
                className="h-60 w-72"
                src={`data:image/jpeg;base64,${insuranceCards?.encoded_front_photo}`}
                alt="Card Front"
              />
            </div>
            <div>
              <div className="mb-2">Back of card</div>
              <img
                className="h-60 w-72"
                src={`data:image/jpeg;base64,${insuranceCards?.encoded_rear_photo}`}
                alt="Card Front"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="my-4 font-bold text-lg">No Cards Uploaded</div>
      )}
    </div>
  );
}
