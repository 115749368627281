import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import UserRecordSection from './user_record_section';
import { format } from 'date-fns';
import LinkButton from '../link_button';
import { useState } from 'react';

interface Score {
  domain: string;
  description: string;
}

interface EwbQuiz {
  id: number;
  user_id: number;
  created_at: string;
  human_readable_answers: string[];
  questions: string[];
  question_1: number;
  question_2: number;
  question_3: number;
  question_4: number;
  question_5: number;
  question_6: number;
  question_7: number;
  question_8: number;
  question_9: number;
  question_10: number;
  question_11: number;
  question_12: number;
  question_13: number;
  question_14: number;
  glows: Score[];
  grows: Score[];
}

export default function EwbQuizzes() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , ewbQuizzes] = useFetchResource<EwbQuiz[]>(
    CoachingApiEndpoints.userEwbQuizzes(userId),
  );

  return (
    <UserRecordSection title="EWB Quiz Results" loading={loading}>
      <ul className="space-y-3">
        {ewbQuizzes?.map((ewbQuiz) => (
          <EwbQuizEntry quiz={ewbQuiz} key={ewbQuiz.id} />
        ))}
      </ul>
    </UserRecordSection>
  );
}

interface EwbQuizEntryProps {
  quiz: EwbQuiz;
}

function EwbQuizEntry({ quiz }: EwbQuizEntryProps) {
  const [showRaw, setShowRaw] = useState(false);
  return (
    <li
      key={quiz.id}
      className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
      <div>
        <div className="mb-2">
          Quiz Taken {format(new Date(quiz.created_at), 'MMMM do yyyy')} at{' '}
          {format(new Date(quiz.created_at), 'h:mm a')}
        </div>

        <div>
          <div className="text-lg font-bold mb-1">Glows</div>
          <div>
            <ResultsTable entries={quiz.glows} />
          </div>
          <div className="text-lg font-bold mt-2 mb-1">Grows</div>
          <div>
            <ResultsTable entries={quiz.grows} />
          </div>
        </div>

        <div className="my-2">
          <LinkButton
            title="Show Raw Scores"
            onClick={() => {
              setShowRaw(!showRaw);
            }}
          />
        </div>

        {showRaw && (
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Question
                </th>
                <th
                  scope="col"
                  className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Answer
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {quiz.human_readable_answers.map((answer, index) => (
                <tr key={index}>
                  <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm font-medium text-gray-900">
                    {quiz.questions[index]}
                  </td>
                  <td className="whitespace-nowrap px-10 py-4 text-sm text-gray-500">
                    {answer}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </li>
  );
}

function ResultsTable({ entries }: { entries: Score[] }) {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Domain
          </th>
          <th
            scope="col"
            className="px-10 py-3.5 text-left text-sm font-semibold text-gray-900">
            Description
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {entries.map((entry, index) => (
          <tr key={index}>
            <td className="whitespace-wrap py-4 pl-4 pr-10 text-sm font-medium text-gray-900">
              {entry.domain}
            </td>
            <td className="px-10 py-4 text-sm text-gray-500">
              {entry.description}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
