import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

const apiBase = process.env.REACT_APP_API_BASE || 'http://localhost:3001';

export const fetchResource = async (apiRoute: string) => {
  const request = async () => {
    return axios.get(apiBase + apiRoute);
  };

  return makeRequest(request);
};

export const postResource = async (
  apiRoute: string,
  payload: Record<string, any>,
) => {
  const request = async () => {
    return axios.post(apiBase + apiRoute, payload);
  };

  return makeRequest(request);
};

export const updateResource = async (
  apiRoute: string,
  payload: Record<string, any>,
) => {
  const request = async () => {
    return axios.put(apiBase + apiRoute, payload);
  };

  return makeRequest(request);
};

export const deleteResource = async (apiRoute: string) => {
  const request = async () => {
    return axios.delete(apiBase + apiRoute);
  };

  return makeRequest(request);
};

const makeRequest = async (
  requestFx: () => Promise<AxiosResponse<any, any>>,
) => {
  let response = null;
  try {
    response = await requestFx();
  } catch (err: any) {
    console.debug('Axios Request error == ', err);
    if (!err.isAxiosError) {
      console.debug(
        'fetchResource encountered an unexpected exception = ',
        err,
      );
      throw err;
    }
    response = err.response;
  }

  return [response.status, response.data];
};

export function useFetchResource<S>(
  apiEndpoint: string,
): [boolean, string | undefined, S | undefined, () => Promise<void>] {
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [apiData, setApiData] = useState<S | undefined>();

  const fetchData = useCallback(async () => {
    setHasFetchedData(true);
    setLoading(true);

    const [status, data] = await fetchResource(apiEndpoint);

    setLoading(false);

    if (status > 200) {
      setError('Unable to fetch resource');
      return;
    }

    setApiData(data);
  }, [apiEndpoint]);

  useEffect(() => {
    if (!loading && !hasFetchedData && !apiData) {
      fetchData();
    }
  }, [apiData, fetchData, hasFetchedData, loading]);

  return [loading, error, apiData, fetchData];
}
