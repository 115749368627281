import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CmsApiEndpoints } from '../../coaching/api_endpoints';
import CoachingButton from '../../coaching/components/coaching_button';
import CoachingModal from '../../coaching/components/coaching_modal';
import { useFetchResource } from '../../utils/api_request_helpers';
import ByteGroupingEntry from '../components/byte_groupings/byte_grouping_entry';
import ByteGroupingForm from '../components/byte_groupings/byte_grouping_form';
import { Byte } from './bytes_page';

export interface ByteGrouping {
  id: number;
  title: string;
  bytes: Byte[];
}

export default function ExploreCategoryGroupingsPage() {
  const params = useParams<{ domainId: string }>();
  const categoryId = params.domainId!;

  const [showForm, setShowForm] = useState(false);

  const [loading, , byteGroupings, refetchByteGroupings] = useFetchResource<
    ByteGrouping[]
  >(CmsApiEndpoints.domainByteGroupings(categoryId));

  if (loading || !byteGroupings) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="mx-10">
        <h2 className="my-4 text-3xl">Domain {categoryId} Content Groupings</h2>
        <div className="my-2">
          <CoachingButton
            onClick={() => {
              setShowForm(!showForm);
            }}
            title="Add Grouping"
          />
        </div>
        {byteGroupings.map((grouping) => (
          <ByteGroupingEntry
            grouping={grouping}
            key={grouping.id}
            refetchData={() => {
              refetchByteGroupings();
            }}
          />
        ))}
      </div>
      <CoachingModal
        open={showForm}
        onClose={() => {
          setShowForm(false);
        }}>
        <ByteGroupingForm
          categoryId={categoryId}
          onSuccess={() => {
            refetchByteGroupings();
            setShowForm(false);
          }}
        />
      </CoachingModal>
    </>
  );
}
