import { useParams } from 'react-router-dom';
import UserRecordSection from './user_record_section';
import ListCard from '../../../shared/components/list_card';
import { CoachingApiEndpoints } from '../../api_endpoints';
import { useFetchResource } from '../../../utils/api_request_helpers';
import HumanReadableDate from '../human_readable_date';

interface ProgramRecord {
  id: number;
  byte_id: number;
  byte_title: string;
  reason_for_recommendation: string;
  completed_at?: string;
  created_at: string;
}

export default function UsersProgramBytes() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , programRecords] = useFetchResource<ProgramRecord[]>(
    CoachingApiEndpoints.userProgramBytes(userId),
  );

  return (
    <UserRecordSection title="Users Program" loading={loading}>
      <ul className="space-y-3">
        {programRecords?.map((programRecord) => (
          <ListCard key={programRecord.id}>
            <div>
              <div>
                Assigned on:{' '}
                <HumanReadableDate isoValue={programRecord.created_at} />
              </div>
              <div>
                Reason for recommendation:{' '}
                {programRecord.reason_for_recommendation}
              </div>
              {programRecord.completed_at && (
                <div className="my-2 font-semibold">
                  Completed on:{' '}
                  <HumanReadableDate isoValue={programRecord.completed_at} />
                </div>
              )}
              <div className="text-lg font-semibold">
                {programRecord.byte_title}
              </div>
            </div>
          </ListCard>
        ))}
      </ul>
    </UserRecordSection>
  );
}
