import { XIcon } from '@heroicons/react/outline';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { SessionNote } from '../../../../types/coaching/session_note';
import {
  postResource,
  updateResource,
} from '../../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../../api_endpoints';
import CoachingModal from '../../coaching_modal';
import FormButton from '../../forms/form_button';
import WaveCheckbox from '../../forms/wave_checkbox';
import WaveDomainSelectInput from '../../forms/wave_domain_select_input';
import WaveMultiSelect from '../../forms/wave_multi_select';
import WaveSelectInput from '../../forms/wave_select_input';
import WaveStringField from '../../forms/wave_string_field';
import WaveTextField from '../../forms/wave_text_field';

const RapportRatingScale = [
  'Disagree: 1',
  'Somewhat Agree: 2',
  'Agree: 3',
  'Strongly agree: 4',
];

interface NewSessionNoteFormProps {
  onClose: () => void;
  onSuccess: () => void;
  existingRecord?: SessionNote;
  show: boolean;
  userId: string;
  isIntakeSession?: boolean;
}
export default function SessionNoteForm({
  existingRecord,
  ...props
}: NewSessionNoteFormProps) {
  const [noteId, setNoteId] = useState<number>();
  const [showSavedSuccess, setshowSavedSuccess] = useState(false);

  useEffect(() => {
    if (!existingRecord?.id) {
      return;
    }
    setNoteId(existingRecord.id);
  }, [existingRecord?.id]);

  const initialValues = {
    reviewed_coach_client_relationship:
      existingRecord?.reviewed_coach_client_relationship || false,
    presenting_concern: existingRecord?.presenting_concern || '',
    past_work_on_presenting_concern:
      existingRecord?.past_work_on_presenting_concern || '',
    reviewed_ewb_results: existingRecord?.reviewed_ewb_results || false,
    reviewed_dass_results: existingRecord?.reviewed_dass_results || false,

    focus_area_target_domain: existingRecord?.focus_area_target_domain || '',
    focus_area_target_skill: existingRecord?.focus_area_target_skill || '',
    focus_area_readiness_to_change:
      existingRecord?.focus_area_readiness_to_change || '',
    focus_area_discussion: existingRecord?.focus_area_discussion || '',

    risk_observed: existingRecord?.risk_observed || [],
    risk_notes: existingRecord?.risk_notes || '',

    progress: existingRecord?.progress || '',

    user_experience_research_note:
      existingRecord?.user_experience_research_note || '',

    percieved_obstacles: existingRecord?.percieved_obstacles || '',
    motivators: existingRecord?.motivators || '',
    strengths: existingRecord?.strengths || '',

    coach_rating_of_rapport: {
      today_rapport:
        existingRecord?.coach_rating_of_rapport?.today_rapport || '',
      communicated_well:
        existingRecord?.coach_rating_of_rapport?.communicated_well || '',
      worked_together_on_goals:
        existingRecord?.coach_rating_of_rapport?.worked_together_on_goals || '',
    },

    internal_note: existingRecord?.internal_note || '',

    user_facing_summary: existingRecord?.user_facing_summary || '',
  };

  const submitToServer = async (values: typeof initialValues) => {
    const fullApiPayload = Object.assign(values, { user_id: props.userId });

    let status, response: any;

    if (noteId) {
      [status] = await updateResource(
        CoachingApiEndpoints.user_session_note(noteId),
        fullApiPayload,
      );
    } else {
      [status, response] = await postResource(
        CoachingApiEndpoints.user_session_notes(props.userId),
        fullApiPayload,
      );

      setNoteId(response['id']);
    }

    if (status > 299) {
      alert('Unable to complete request, please try again');
      return;
    }

    setshowSavedSuccess(true);
    setTimeout(() => {
      setshowSavedSuccess(false);
    }, 1000);

    props.onSuccess();
  };
  return (
    <CoachingModal open={props.show} onClose={() => {}}>
      <div>
        <div className="flex justify-end">
          <XIcon height={28} width={28} onClick={props.onClose} />
        </div>
      </div>
      <div className="w-full">
        <Formik initialValues={initialValues} onSubmit={submitToServer}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="space-y-8">
              <div className="mt-6 space-y-6 mx-4">
                {props.isIntakeSession && (
                  <>
                    <WaveCheckbox
                      name="reviewed_coach_client_relationship"
                      label="Reviewed Coaching Client Relationship?"
                      required
                    />

                    <WaveTextField
                      name="presenting_concern"
                      label="Presenting Concern"
                    />

                    <WaveTextField
                      name="past_work_on_presenting_concern"
                      label="Past work on presenting concern"
                    />
                  </>
                )}

                <WaveCheckbox
                  name="reviewed_ewb_results"
                  label="Reviewed EWB Quiz Results?"
                />

                <WaveCheckbox
                  name="reviewed_dass_results"
                  label="Reviewed DASS Results?"
                />

                <WaveDomainSelectInput
                  name="focus_area_target_domain"
                  label="Focus Area Target Domain"
                />

                <WaveStringField
                  name="focus_area_target_skill"
                  label="Focus Area Target Skill"
                />

                <WaveSelectInput
                  name="focus_area_readiness_to_change"
                  label="Focus Area Readiness to Change"
                  options={[
                    'Precontemplation',
                    'Contemplation',
                    'Preparation',
                    'Action',
                    'Maintenance',
                  ]}
                />

                <WaveTextField
                  name="focus_area_discussion"
                  label="Focus Area Discussion"
                />

                <WaveMultiSelect
                  name="risk_observed"
                  label="Risk Observed (leave blank if none)"
                  options={[
                    'SI = suicidal ideation',
                    'NSSI = non suicidal self injury',
                    'HI = homicidal ideation',
                    'VH = visual hallucinations',
                    'AH = auditory hallucinations',
                    'ED = Eating disorder',
                    'Other (specify in supplemental note)',
                  ]}
                />

                <WaveTextField
                  name="risk_notes"
                  label="Risk Supplemental Notes (leave blank if none)"
                />

                {!props.isIntakeSession && (
                  <>
                    <WaveSelectInput
                      name="progress"
                      label="Progress"
                      options={[
                        'Client Seems to be further away from their goals',
                        'Client has not (or not yet) made progress towards their goals',
                        'Client has made slight progress towards their goals',
                        'Client has made some progress towards their goals',
                        'Client has made major or excellent progress towards their goals',
                        "It is difficult to assess client's progress at this time",
                      ]}
                    />

                    <WaveTextField
                      name="user_experience_research_note"
                      label="Feedback regarding user experience research"
                    />
                  </>
                )}

                {props.isIntakeSession && (
                  <>
                    <WaveTextField
                      name="percieved_obstacles"
                      label="Perceived Obstacles"
                      required
                    />

                    <WaveTextField
                      name="motivators"
                      label="Motivators"
                      required
                    />

                    <WaveTextField
                      name="strengths"
                      label="Strengths"
                      required
                    />
                  </>
                )}

                <WaveSelectInput
                  name="coach_rating_of_rapport.today_rapport"
                  label="Today my client and I had good rapport"
                  options={RapportRatingScale}
                />
                <WaveSelectInput
                  name="coach_rating_of_rapport.communicated_well"
                  label="Today my client and I communicated well"
                  options={RapportRatingScale}
                />
                <WaveSelectInput
                  name="coach_rating_of_rapport.worked_together_on_goals"
                  label="Today my client and I are worked together on mutually agreed upon goals"
                  options={RapportRatingScale}
                />

                <WaveTextField name="internal_note" label="Extra Notes" />

                <div className="p-4 bg-blue-100 rounded-md">
                  <WaveTextField
                    name="user_facing_summary"
                    label="User Facing Summary (User will see this content in the App)"
                  />
                </div>

                {showSavedSuccess && (
                  <div className="bg-green-300 w-full text-gray-500 py-4 px-4 rounded-md">
                    Successfully Saved
                  </div>
                )}

                <div className="flex space-x-8">
                  <FormButton title="Save" />
                  <button
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault();
                      props.onClose();
                    }}
                    className="py-2 px-3 rounded-md shadow-sm text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </CoachingModal>
  );
}
